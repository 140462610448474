<template>
  <v-row no-gutters>
    <v-col cols="12" class="pa-0">
      <skeleton-list v-if="$store.state.accounts.LoaderMe || $store.state.documents.retrieveLoader" />
      <template v-else>
        <PermissionDenied v-if="!$helpers.hasSomePermission(['view_document'])" :isDetail="routeList.includes($route.name)" />
        <template v-else>
          <v-empty-state :class="{'mt-n2' : routeList.includes($route.name)}" v-if="!documentsList.length || (!routeList.includes($route.name) && Object.keys($route?.query).length === 0)" type="documents" id="documentos"
          :customClass="routeList.includes($route.name) ? 'mt-n6 pb-2' : 'mt-n8'" :isFree="routeList.includes($route.name)" title="No existen documentos para mostrar" :hiddenDescription="hiddenBtnEmpty" :height="routeList.includes($route.name) ? 199 : 0">
            <template v-slot:actions>
              <template v-if="!routeList.includes($route.name) && hiddenBtnEmpty">
                <v-btn @click="$emit('activeBtnFilter')" color="primary" :ripple="false" :width="153"><v-icon class="mr-2">mdi-filter-plus</v-icon>Filtrar</v-btn>
                <v-btn class="ml-2" @click="latestRecords()" outlined :ripple="false" width="153"><v-icon class="mr-2">mdi-text-search-variant</v-icon>Últimos registros</v-btn>
              </template>
            </template>
          </v-empty-state>
          <template v-else>
            <v-data-table
              :class="[{'table-not-rounded' : ['BranchRetrieveUpdate', 'PosRetrieveUpdate', 'DocumentsRetrieve', 'CustomerRetrieveUpdate'].includes($route.name)}, {'fixed-table' : $store.state.base.isExpandNavigationDrawer}]"
              :headers="currentHeader"
              :items="documentsList"
              :items-per-page="pageSize || pagination"
              hide-default-header
              disable-sort
              hide-default-footer
              @click:row="(documentsList) => { goTo(documentsList.id) }"
              style="cursor: pointer"
              mobile-breakpoint="300"
              item-key="id"
              v-model="selected"
              :show-select="!['BranchRetrieveUpdate', 'PosRetrieveUpdate', 'DocumentsRetrieve', 'CustomerRetrieveUpdate', 'DocumentsBatchRetrieve', 'SupplierRetrieveUpdate'].includes($route.name)"
              checkbox-color="primary"
              :fixed-header="!['BranchRetrieveUpdate', 'PosRetrieveUpdate', 'DocumentsRetrieve', 'CustomerRetrieveUpdate', 'DocumentsBatchRetrieve', 'SupplierRetrieveUpdate'].includes($route.name)"
              :height="heightTable"
              >
              <!-- header -->
              <template v-slot:header="{ props: { headers } }">
                <VTableHeaders :headers="headers" @sort="setSort" @toggleAll="toggleAll" @isSelected="isSelected = $event" :resetCheckbox="resetCheckbox" @toggleDropdown="toggleDropdown = $event" />
                <v-divider style="position: absolute;  margin-top: -6px; min-width: 100%; margin-left: -5px; z-index: 2" />
              </template>
              <!-- end header -->
              <template v-slot:[`item.customer`]="{item}">
                <router-link :to="{ name: 'DocumentsRetrieve', params: {...$route.params, id: item.id} }" class="fontLinkTable--text text-decoration-none">
                  <span v-if="((item || {}).customer || {}).name" class="d-inline-block text-truncate" :class="routeList.includes($route.name) ? 'body-2' : 'body-1 mb-n2'" style="max-width: 96%;">{{((item || {}).customer || {}).name}}</span>
                  <span :class="`${routeList.includes($route.name) ? 'body-2' : 'body-1'}, text--disabled font-italic`" v-else>Sin especificar</span>
                  <span class="caption fontDraw--text d-block" v-if="((item || {}).customer || {}).taxpayer?.tax_id">{{$t('generals.RUT')}} {{item.customer?.taxpayer?.tax_id}}</span>
                  <span :class="`${routeList.includes($route.name) ? 'body-2' : 'body-1'}, text--disabled font-italic`" v-else>Sin especificar</span>
                </router-link>
              </template>
              <template v-slot:[`item.customer_name`]="{item}">
                <router-link :to="`/documents/${item.id}`" class="fontLinkTable--text text-decoration-none">
                  <span v-if="item.customer_name" class="d-inline-block text-truncate" :class="routeList.includes($route.name) ? 'body-2' : 'body-1 mb-n2'" style="max-width: 100%;">{{item.customer_name}}</span>
                  <span :class="`${routeList.includes($route.name) ? 'body-2' : 'body-1'}, text--disabled font-italic`" v-else>Sin especificar</span>
                  <span class="caption fontDraw--text d-block" v-if="item.customer_tax_id">{{$t('generals.RUT')}} {{item?.customer_tax_id}}</span>
                  <span :class="`${routeList.includes($route.name) ? 'body-2' : 'body-1'}, text--disabled font-italic`" v-else>Sin especificar</span>
                </router-link>
              </template>
              <template v-slot:[`item.type`]="{item}">
                <router-link v-if="$route.name === 'DocumentsBatchRetrieve'" :to="{ name: 'DocumentsRetrieve', params: {...$route.params, id: item.id} }" class="fontLinkTable--text text-decoration-none">
                  <span :class="routeList.includes($route.name) ? 'body-2' : 'body-1'" style="white-space: normal !important">{{item.type | nameDocumentsType}}</span>
                </router-link>
                <router-link v-else :to="{ name: 'DocumentsRetrieve', params: {...$route.params, id: item.id} }" class="fontLinkTable--text text-decoration-none">
                  <span :class="routeList.includes($route.name) ? 'body-2' : 'body-1'" style="white-space: normal !important">{{item.type | nameDocumentsType}}</span>
                </router-link>
              </template>
              <template v-slot:[`item.number`]="{item}">
                <span :class="routeList.includes($route.name) ? 'body-2' : 'body-1'">{{ item.number }}</span>
              </template>
              <template v-slot:[`item.date`]="{item}">
                <span :class="routeList.includes($route.name) ? 'body-2' : 'body-1'">{{item.date | date}}</span>
              </template>
              <template v-slot:[`item.created`]="{item}">
                <span :class="routeList.includes($route.name) ? 'body-2' : 'body-1'">{{item.created | dateTimeSecond}}</span>
              </template>
              <template v-slot:[`item.status`]="{item}">
                <chip-status :status="item.status ? item.status : 'CRE'" :detail="item?.agency_status_detail_list || item?.agency_status_info ? `${setTooltip(item.agency_status_detail_list) || item.agency_status_info}` : null" :isTooltip="true" :isActivePopover="true" />
              </template>
              <template v-slot:[`item.amount_total`]="{item}">
                <span :class="routeList.includes($route.name) ? 'body-2' : 'body-1'">{{item.amount_total}}</span>
              </template>
              <template v-slot:[`item.amount_total`]="{item}">
                <span :class="routeList.includes($route.name) ? 'body-2' : 'body-1'">{{item.amount_total}}</span>
                <span class="fontDraw--text caption ml-1">{{item?.currency || $store?.state?.auth?.account.base_currency.id}}</span>
              </template>
              <template v-slot:[`item.actions`]="{item}">
                <v-menu close-on-click close-on-content-click offset-y left :nudge-left="10" :nudge-bottom="10" transition="slide-y-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mr-n4" v-bind="attrs" v-on="on" text @click="current = item" x-small><v-icon color="fontBody" size="24">mdi-dots-vertical</v-icon></v-btn>
                  </template>
                  <v-list class="pa-0">
                    <v-list-item class="px-3" @click="renderDocument2('documents', null, 'pdf', item.id), resetPDF += 1" :ripple="false">
                      <v-list-item-title class="d-flex align-center body-1 fontDraw--text"><v-icon color="secondary" size="20" left>mdi-text-box</v-icon>Ver PDF</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="px-3" @click="downloadFile2('documents', `${item.type}_${item.number}`, null, 'xml', item.id)" :ripple="false">
                      <v-list-item-title class="d-flex align-center body-1 fontDraw--text"><v-icon color="secondary" size="20" left>mdi-code-braces-box</v-icon>Descargar documento</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="['APR', 'OBS'].includes(item.status)" class="px-3" @click="downloadFile2('documents', `${item.type}_${item.number}`, null, 'voucher', item.id)" :ripple="false">
                      <v-list-item-title class="d-flex align-center body-1 fontDraw--text"><v-icon color="secondary" size="20" left>mdi-code-braces-box</v-icon>Descargar CDR</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
            <VMainPagination v-if="showPagination || showAll" titleShowAll="Ver todos los documentos" :count="count" :pageSize="pageSize" redirect="DocumentsList"  />
          </template>
        </template>
      </template>
      <!-- dialog pdf -->
      <v-dialog v-model="modalRender" width="700" persistent scrollable no-click-animation overlay-color="overlay">
        <render-pdf :basicRender="true" :documentType="current.type | nameDocumentsType" :number="current.number" :loaderRender="loaderRender" :renderUrl="renderUrl" @close="modalRender=false" :formatPDF.sync="formatPDF" :showFormat="true" :key="resetPDF" />
      </v-dialog>
      <!-- end dialog pdf -->
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import VEmptyState from '@/components/commons/VEmptyState'
import RenderPdf from '@/components/files/RenderPdf'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import ListViewMixin from '@/mixins/ListViewMixin'
import RenderViewMixin from '@/mixins/RenderViewMixin'
import ChipStatus from '@/components/commons/ChipStatus'
import VMainPagination from '@/components/commons/VMainPagination'
import PermissionDenied from '@/modules/http/views/PermissionDenied'

export default {
  components: {
    ChipStatus,
    RenderPdf,
    VEmptyState,
    SkeletonList,
    VTableHeaders,
    VMainPagination,
    PermissionDenied
  },
  mixins: [
    ListViewMixin,
    GenericViewMixin,
    RenderViewMixin
  ],
  props: {
    activeDialogExport: {
      type: Boolean,
      default: false
    },
    headerList: {
      type: Array,
      default: () => ([])
    },
    pageSize: {
      default: null
    },
    hasPermission: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    resetPDF: 0,
    toggleDropdown: null,
    formatPDF: 1,
    dialogExport: false,
    dialogExportProgress: false,
    resetCheckbox: 0,
    isSelected: null,
    selected: [],
    current: {},
    widthList: [266, 170, 100, 100, 100, 80, 46],
    widthDetailList: [80, 150, 50, 55, 50, 50, 16],
    routeList: ['PosRetrieveUpdate', 'BranchRetrieveUpdate', 'DocumentsRetrieve', 'PurchaseRetrieve', 'CustomerRetrieveUpdate', 'DocumentsBatchRetrieve'],
    env: process.env?.VUE_APP_MODE ?? 'ax',
    headersDetail: [
      { text: 'Cliente', value: 'customer_name', sortable: true },
      { text: 'Tipo de doc.', value: 'type', sortable: true },
      { text: 'Folio#', value: 'number', align: 'end', sortable: true },
      { text: 'Fecha del doc.', value: 'date', align: 'end', sortable: true },
      { text: 'Estado', value: 'status', show: true, disabled: false },
      { text: 'Total', value: 'amount_total', align: 'end', sortable: true },
      { text: '', value: 'actions', align: 'end', show: true}
    ]
  }),
  computed: {
    ...mapState({
      count: state => state.documents.documentsCount,
      documentsList: state => state.documents.documentsList
    }),
    heightTable () {
      if (this.$vuetify.breakpoint.height < 900) return 'calc(100vh - 275px)'
      else if (this.documentsList.length < 20) return 'auto' // his.$vuetify.breakpoint.height <= 900 &&
      else if (['BranchRetrieveUpdate', 'PosRetrieveUpdate', 'DocumentsRetrieve', 'CustomerRetrieveUpdate', 'DocumentsBatchRetrieve', 'SupplierRetrieveUpdate'].includes(this.$route.name)) return 'auto'
      return 'calc(100vh - 275px)'
    },
    currentHeader () {
      let list = !['BranchRetrieveUpdate', 'PosRetrieveUpdate', 'DocumentsRetrieve', 'CustomerRetrieveUpdate', 'DocumentsBatchRetrieve', 'SupplierRetrieveUpdate'].includes(this.$route.name) ? [...this.headerList] : [...this.headersDetail]
      list.forEach((item, index) => {
        if (this.routeList.includes(this.$route.name)) {
          if (item.value === 'number') {
            item.text = this.$t('generals.Folio#')
            item.width = 140
          }
          if (this.$store.state?.auth?.account?.country === 'PE' && item.value === 'type') item.width = 140
          // else item.width = this.widthDetailList[index]
        }

        if (this.$store.state?.auth?.account?.country === 'PE' && item.value === 'number') {
          item.align = 'start'
          item.width = 160
          item.text = this.$t('generals.Folio #')
        }
      })
       return list
    },
    checkPermissionDTE () {
      if (!!this.$store.state.accounts.me?.is_owner && !this.$store.state.auth.userAccount.is_staff) {
        /* eslint-disable */
        const ownGroupPermissions = [...(this.$store.state.accounts.me?.document_type_permissions ?? []), ...(this.$store.state.accounts.me?.groups?.map(({document_type_permissions}) => document_type_permissions)[0] ?? [])]
        return !ownGroupPermissions.filter(({permission}) => permission === 'view_document').length
      }
      return false
    },
    hiddenBtnEmpty () {
      if (!Object.keys(this.$route.query ?? {}).length) return true
      else if (!!this.documentsList.length) return false
      return false
    },
    currentParams () {
      let params = JSON.parse(JSON.stringify(this.$route.params))
      delete params.id
      return params
    },
    setTooltip () {
      return (list = {}) => {
        let array = []
        if (list?.length) {
          list.forEach((item) => {
            array = [...array, `${item} &&`]
          })
        }
        return array.toString().replace(/[&&,]/g, '<br>')
      }
    }
  },
  watch: {
    selected (val) {
      if (val.length !== this.documentsList.length) this.resetCheckbox += 1

      this.$emit('update:selectedDocuments', this.selected)
    },
    toggleDropdown (val) {
      if (['filtered', 'perPage', 'all'].includes(val)) {
        this.selected = this.documentsList
        this.isSelected = true
      } else if (val === '') this.selected = []
      this.$emit('update:toggleDropdown', val)
    },
    activeDialogExport (val) {
      this.dialogExport = val
    },
    formatPDF: {
      handler (val) {
        if (val === 1) this.renderDocument('documents', null, 'pdf', this.current.id)
        else {
          this.renderDocument('documents', 'documents', 'pdf', this.current.id, { thermal_format: val})
        }
      },
      inmediate: true,
      deep: true
    }
  },
  methods: {
    goTo (id = null) {
      this.$store.commit('base/SET_QUERY', this.$route.query)
      this.$router.push({ name: 'DocumentsRetrieve', params: { id: id, ...this.currentParams }})
    },
    latestRecords () {
     this.$router.replace({name: this.$route.name, params: this.currentParams, query: { ref: 'initial'} }).catch(() => {})
    },
    toggleAll () {
      this.selected = !this.isSelected ? [] : this.documentsList
    },
    handleExport () {
      this.dialogExport = false
      this.dialogExportProgress = true
      this.$emit('update:activeDialogExport', false)
    }
  }
}
</script>