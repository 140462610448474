<template>
  <v-navigation-drawer class="panel" v-model="activePanel" app temporary @input="mini = !mini" right width="600" flat overlay-color="overlay">
    <div style="position: fixed; z-index: 1000 !important; min-width: 100%">
      <v-toolbar flat height="46" color="bgSearch">
        <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-bold">{{title}}</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-row align="center" justify="center" class="mr-3">
            <v-btn @click="closePanel" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
          </v-row>
        </v-toolbar-items>
      </v-toolbar>
      <v-divider />
    </div>
    <div>
      <slot />
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    activePanel: false,
    mini: false
  }),
  watch: {
    active: {
      handler (val) {
        this.activePanel = val
        if (val) {
          document.documentElement.style.overflowY = 'hidden'
          document.body.style.overflowY = 'hidden'
        }
      },
      inmediate: true,
      deep: true
    },
    mini: {
      handler (val) {
        if (!val) {
          this.closePanel()
          this.$emit('close')
        }
      },
      inmediate: true,
      deep: true
    }
  },
  methods: {
   closePanel () {
      this.activePanel = false
      document.documentElement.style.overflowY = 'auto'
      document.body.style.overflowY = 'auto'
     // this.$emit('close')
    }
  }
}
</script>