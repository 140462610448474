export default [
  {
    id: 'PERR',
    name: 'Informe de reversiones',
    country: 'PE'
  },
  {
    id: 'PERA',
    name: 'Informe de bajas',
    country: 'PE'
  },
  {
    id: 'PERC',
    name: 'Informe de bajas de boletas',
    country: 'PE'
  }
]