<template>
  <v-dialog v-model="dialog" width="980" persistent no-click-animation scrollable :fullscreen="fullscreen" overlay-color="overlay">
    <v-card class="pa-0">
      <v-card-title class="pa-0">
        <v-toolbar flat height="55">
          <v-toolbar-title class="subtitle-1 text--secondary font-weight-medium">Crear nota de crédito<v-chip class="ml-3" color="primary" text-color="white" small>Beta</v-chip></v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-1">
              <v-tooltip color="bgSearch" bottom max-width="280px" transition="scale-transition" open-delay="1000">
                <template v-slot:activator="{ on }">
                  <v-btn class="ml-3" v-on="on" @click="fullscreen=!fullscreen" icon small>
                    <v-icon v-if="!fullscreen" size="24">mdi-fullscreen</v-icon>
                    <v-icon v-else size="24">mdi-fullscreen-exit</v-icon>
                  </v-btn>
                </template>
                <span v-if="!fullscreen">Pantalla completa</span>
                <span v-else>Salir de pantalla completa</span>
              </v-tooltip>
              <v-btn class="ml-3" @click="dialog=false" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-0">
        <v-row>
          <v-col cols="12" class="px-3 py-0">
            <v-toolbar color="transparent" flat height="55">
              <v-toolbar-title class="text--secondary subtitle-1 font-weight-medium">Items de crédito</v-toolbar-title>
            </v-toolbar>
            <v-divider />
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th class="text-left" style="width:80px">Exento</th>
                    <th class="text-right" style="width:100px;">Cant.</th>
                    <th class="text-right" style="width:140px">Precio</th>
                    <th class="text-right" style="width:200px">Descuento</th>
                    <th class="text-right" style="width:120px">Subtotal</th>
                    <th class="text-right" style="width:60px"></th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(d, i) in creditNote.lines">
                    <tr class="no-hover" :key="i">
                      <td>
                        <v-text-field v-model.trim="d.name" :error="$v.creditNote.lines.$each[i].name.$error" background-color="transparent" solo required single-line flat dense placeholder="Escribe un nombre para el producto" maxlength="80" hide-details />
                        <v-text-field v-model.trim="d.description" :error="$v.creditNote.lines.$each[i].description.$error" background-color="transparent" required single-line class="mt-n4" solo flat dense placeholder="Descripción del producto" maxlength="1000" hide-details />
                      </td>
                      <td>
                        <v-switch v-model="d.exempt" :disabled="!documents.document.has_taxes" />
                      </td>
                      <td>
                        <v-tooltip color="bgSearch"  top max-width="280px" transition="scale-transition" :disabled="$v.creditNote.lines.$each[i].quantity.required">
                          <template v-slot:activator="{ on }">
                            <v-text-field v-on="on" v-model.number="$v.creditNote.lines.$each[i].quantity.$model" :error="$v.creditNote.lines.$each[i].quantity.$error" oninput="this.value=this.value.replace(/[^0-9.]/g,'');" required background-color="transparent" single-line reverse outlined dense placeholder="0" maxlength="12" hide-details />
                          </template>
                          La cantidad del item no puede estar en blanco. El valor mínimo es 0.
                        </v-tooltip>
                      </td>
                      <td>
                        <v-tooltip color="bgSearch" top max-width="280px" transition="scale-transition" :disabled="$v.creditNote.lines.$each[i].amount.required">
                          <template v-slot:activator="{ on }">
                            <v-text-field v-on="on" v-model.number="$v.creditNote.lines.$each[i].amount.$model" :error="$v.creditNote.lines.$each[i].amount.$error" oninput="this.value=this.value.replace(/[^0-9.]/g,'');" required background-color="transparent" single-line reverse outlined dense placeholder="0" maxlength="12" hide-details />
                          </template>
                          El precio del item no puede estar en blanco. El valor mínimo es 0.
                        </v-tooltip>
                      </td>
                      <td>
                        <v-row justify="end" dense>
                          <v-col cols="5">
                            <v-select v-model="d.discount_scheme" :items="discountSchemes" item-text="name" item-value="value" hide-details="true" solo outlined flat dense>
                              <template v-slot:selection="{ item }"><span class="px-1 ml-1">{{item.value}}</span></template>
                            </v-select>
                          </v-col>
                          <v-col cols="7">
                            <v-tooltip color="bgSearch" top max-width="280px" transition="scale-transition" :disabled="$v.creditNote.lines.$each[i].discount.maxValue">
                              <template v-slot:activator="{ on }">
                                <v-text-field v-on="on" v-model.number="$v.creditNote.lines.$each[i].discount.$model" :error="$v.creditNote.lines.$each[i].discount.$error" oninput="this.value=this.value.replace(/[^0-9.]/g,'');" required background-color="transparent" single-line reverse outlined dense placeholder="0" maxlength="12" hide-details />
                              </template>
                              <template v-if="creditNote.lines[i].discount_scheme === '%'">El porcentaje del descuento no puede ser mayor al 100% del subtotal del item.</template>
                              <template v-if="creditNote.lines[i].discount_scheme === '$'">El monto del descuento no puede ser mayor a {{(creditNote.lines[i].amount || 0) * (creditNote.lines[i].quantity || 0) | currency(documents.currency)}} {{documents.currency}}</template>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </td>
                      <td class="text-right" style="max-width:156px">
                        <span class="text--secondary body-2 font-weight-medium" v-if="$v.creditNote.lines.$each[i].amount.$error || $v.creditNote.lines.$each[i].discount.$error || $v.creditNote.lines.$each[i].quantity.$error">-</span>
                        <span class="text--secondary body-2 font-weight-medium" v-else>{{aggregations.lines[i].total | currency(documents.currency)}} {{documents.currency}}</span>
                      </td>
                      <td class="text-right">
                        <v-btn v-if="i >= 1 || creditNote.lines.length > 1" @click="creditNote.lines.splice(i, 1)" icon small color="red"><v-icon size="18">mdi-close</v-icon></v-btn>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-simple-table>
            <v-divider />
          </v-col>
        </v-row>
        <v-row v-if="totalAdjusteDocuments < 0">
          <v-col cols="12" class="px-7 pb-0">
            <v-alert class="mb-0" text dense border="left" type="error" icon="mdi-alert">
              El total de la nota de crédito supera el total de la factura ({{totalDocuments | currency(documents.currency)}} {{documents.currency}}). Reduce la cantidad o precio en los items.
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="px-7">
            <v-btn @click="addLine" text color="primary" :ripple="false"><v-icon left>mdi-plus</v-icon>Agregar un item de crédito</v-btn>
          </v-col>
          <v-col cols="6" class="px-3">
            <v-list class="pa-0 transparent">
              <v-list-item class="py-0">
                <v-list-item-title>Subtotal</v-list-item-title>
                <v-list-item-subtitle class="text-right font-weight-medium">
                  <template v-if="totalAdjusteDocuments < 0 || linesWithError.length">-</template>
                  <template v-else>{{aggregations.subtotal | currency(documents.currency)}}</template>
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item class="py-0">
                <v-list-item-title>Descuento</v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  <v-row align="center" justify="end" dense>
                    <v-col cols="4">
                      <v-select class="mr-1" v-model="creditNote.discount_scheme" :items="discountSchemes" item-text="name" item-value="value" hide-details="true" solo outlined flat dense>
                        <template v-slot:selection="{ item }"><span class="px-1 ml-1">{{item.value}}</span></template>
                      </v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-tooltip color="bgSearch" top max-width="280px" transition="scale-transition" :disabled="$v.creditNote.discount.maxValue">
                        <template v-slot:activator="{ on }">
                          <v-text-field v-on="on" v-model.number="$v.creditNote.discount.$model" :error="$v.creditNote.discount.$error" oninput="this.value=this.value.replace(/[^0-9]/g,'');" reverse outlined required single-line dense maxlength="20" placeholder="0" hide-details />
                        </template>
                        <template v-if="creditNote.discount_scheme === '%'">El descuento no puede superar el 100%</template>
                        <template v-if="creditNote.discount_scheme === '$'">El monto del descuento no puede ser mayor a {{aggregations.subtotal | currency(documents.currency)}} {{documents.currency}}</template>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <span v-if="aggregations.discount && creditNote.discount_scheme === '%'">- {{aggregations.discount | number}}</span>
                </v-list-item-subtitle>
              </v-list-item>
              <v-divider class="ml-4 my-1" />
              <template v-if="documents.document.has_taxes_detail">
                <v-list-item class="py-0">
                  <v-list-item-title>Exento</v-list-item-title>
                  <v-list-item-subtitle class="text-right font-weight-medium">
                    <template v-if="totalAdjusteDocuments < 0 || linesWithError.length">-</template>
                    <template v-else>{{aggregations.exempt | currency(documents.currency)}}</template>
                  </v-list-item-subtitle>
                </v-list-item>
                <template v-if="documents.document.has_taxes">
                  <v-list-item class="py-0">
                    <v-list-item-title>Neto</v-list-item-title>
                    <v-list-item-subtitle class="text-right font-weight-medium">
                      <template v-if="totalAdjusteDocuments < 0 || linesWithError.length">-</template>
                      <template v-else>{{aggregations.amount | currency(documents.currency)}}</template>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-divider class="ml-4 my-1" />
                  <v-list-item>
                    <v-list-item-title>Impuestos ({{documents.document.tax}}%)</v-list-item-title>
                    <v-list-item-subtitle class="text-right font-weight-medium">
                      <template v-if="totalAdjusteDocuments < 0 || linesWithError.length">-</template>
                      <template v-else>{{aggregations.taxes | currency(documents.currency)}}</template>
                    </v-list-item-subtitle>
                  </v-list-item>
                </template>
                <v-divider class="ml-4 my-1" />
              </template>
              <v-list-item class="py-0">
                <v-list-item-title class="text-left body-1 text--secondary font-weight-medium">Total de la nota crédito</v-list-item-title>
                <v-list-item-subtitle class="text-right font-weight-medium">
                  <template v-if="totalAdjusteDocuments < 0 || linesWithError.length">-</template>
                  <template v-else>{{aggregations.total | currency(documents.currency)}} {{documents.currency}}</template>
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item class="py-0 mt-6">
                <v-list-item-title class="text-left text--secondary font-weight-medium">
                  Total de la factura
                  <v-tooltip color="bgSearch" right max-width="280px" transition="scale-transition">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small color="secondary">mdi-information</v-icon>
                    </template>
                    <span>Es el total original de la factura menos el total de todas las notas de crédito previamente creadas.</span>
                  </v-tooltip>
                </v-list-item-title>
                <v-list-item-subtitle class="text-right text--secondary font-weight-medium" :class="{'red--text': aggregations.total < 0}">{{totalDocuments | currency(documents.currency)}} {{documents.currency}}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item class="py-0">
                <v-list-item-title class="text-left text--secondary font-weight-medium">Total de la factura ajustada</v-list-item-title>
                <v-list-item-subtitle class="text-right font-weight-medium">
                  <template v-if="totalAdjusteDocuments < 0 || linesWithError.length">-</template>
                  <template v-else>{{totalAdjusteDocuments | currency(documents.currency)}} {{documents.currency}}</template>
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-divider class="my-3" />
        <v-row>
          <v-col cols="10" class="py-0">
            <v-list class="v-list-form transparent">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="mr-3 text--secondary font-weight-medium">Fecha</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-row class="mx-0">
                      <v-col cols="6">
                        <v-menu v-model="dateMenu" :close-on-content-click="false" offset-y transition="scale-transition" max-width="280px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="$v.creditNote.date.$model" :error="$v.creditNote.date.$error" append-icon="mdi-calendar-month" v-bind="attrs" v-on="on" outlined single-line dense readonly hide-details />
                          </template>
                          <v-date-picker v-model="$v.creditNote.date.$model" no-title @input="dateMenu=false" :min="documents.date" :max="today" type="date" locale="es-cl" width="280" color="blue">
                            <div class="mx-auto">
                              <v-btn color="primary" @click="() => {$v.creditNote.date.$model = today}">Hoy</v-btn>
                              <v-btn class="ml-2" outlined @click="() => {$v.creditNote.date.$model = ''}">Limpiar</v-btn>
                            </div>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="mr-3 text--secondary font-weight-medium">Razón de la anulación</span>
                    <v-tooltip color="bgSearch" right max-width="280px" transition="scale-transition">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small color="secondary">mdi-information</v-icon>
                      </template>
                      <span>Escribe una razón por la cual crearás la nota de crédito. Esto será visible para tu cliente.</span>
                    </v-tooltip>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-text-field v-model="$v.creditNote.note.$model" :error="$v.creditNote.note.$error" outlined hide-details required single-line dense maxlength="60" />
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="mr-3 text--secondary font-weight-medium">Nota interna <v-chip color="defaultGrey" text-color="secondary" small>Opcional</v-chip></span>
                    <v-tooltip color="bgSearch" right max-width="280px" transition="scale-transition">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small color="secondary">mdi-information</v-icon>
                      </template>
                      <span>Utiliza este campo para proveer información adicional sobre el ajuste de crédito. Esto no será visible para tu cliente.</span>
                    </v-tooltip>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-textarea v-model="$v.creditNote.internal_note.$model" :error="$v.creditNote.internal_note.$error" outlined hide-details required maxlength="512" />
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions class="pa-4">
        <v-btn @click="setCreditNote" text color="primary" :ripple="false"><v-icon left>mdi-undo-variant</v-icon>Restablecer valores</v-btn>
        <v-spacer />
        <v-btn @click="dialog=false" outlined color="secondary">Cancelar</v-btn>
        <v-btn @click="create" :loading="createLoader" color="primary">Crear nota de crédito</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'
import { decimal, maxLength, numeric, required } from 'vuelidate/lib/validators'
import CreateViewMixin from '@/mixins/CreateViewMixin'
import TaxAgencyViewMixin from '@/mixins/TaxAgencyViewMixin'
export default {
  mixins: [
    CreateViewMixin,
    TaxAgencyViewMixin
  ],
  data: () => ({
    aggregations: {
      lines: [
        {
          total: 0
        }
      ],
      amount: 0,
      exempt: 0,
      subtotal: 0,
      taxes: 0,
      total: 0
    },
    createLoader: false,
    creditNote: {
      date: '',
      discount: 0,
      discount_scheme: '%',
      documents: '',
      internal_note: '',
      lines: [{
        amount: null,
        description: '',
        discount: 0,
        discount_scheme: '%',
        exempt: false,
        name: '',
        quantity: null
      }],
      note: ''
    },
    dateMenu: false,
    discountSchemes: [
      { name: '% - Porcentaje', value: '%' },
      { name: '$ - Monto', value: '$'}
    ],
    dialog: false,
    fullscreen: false
  }),
  created () {
    this.setCreditNote()
  },
  computed: {
    activeCurrency () {
      return this.currencies.find(c => c.id === this.documents.currency) || {}
    },
    aggregationsSource () {
      return {
        discount: this.creditNote.discount,
        discount_scheme: this.creditNote.discount_scheme,
        lines: this.creditNote.lines.map(line => {
          return {
            amount: line.amount,
            discount: line.discount,
            discount_scheme: line.discount_scheme,
            exempt: line.exempt,
            quantity: line.quantity
          }
        })
      }
    },
    handledErrors () {
      return this.baseHandledErrors.concat([
        {
          action: 'Cargar folios',
          message: 'La cuenta no tiene un folio válido para Nota de crédito.',
          route: { name: 'BuiltIn', query: { ref: 'folios' } },
          snippet: 'not have a valid folio'
        }
      ])
    },
    linesWithError () {
      const lines = this.$v.creditNote.lines
      return this.creditNote.lines.filter(
        (line, index) => lines.$each[index].amount.$error || lines.$each[index].discount.$error || lines.$each[index].quantity.$error
      )
    },
    totalCredits () {
      return this.sumProperty(this.creditnotesList, 'total')
    },
    totalDebits () {
      return this.sumProperty(this.debitnotesList, 'total')
    },
    totalDocuments () {
      return this.documents.total - this.totalCredits + this.totalDebits
    },
    totalAdjustedDocuments () {
      return this.totalDocuments - this.aggregations.total
    },
    ...mapState({
      documents: state => state.documents.documentsDetail,
      creditnotesList: state => state.documents.creditnotesList,
      debitnotesList: state => state.documents.debitnotesList
    })
  },
  watch: {
    dialog: {
      handler (newValue) {
        this.$emit('update:dialog', newValue)
      }
    },
    showCreate: {
      handler (newValue) {
        if (newValue) {
          this.dialog = true
        } else {
          this.dialog = false
        }
      }
    },
    aggregationsSource: {
      handler () {
        this.setAggregations()
      },
      deep: true
    }
  },
  methods: {
    create () {
      this.$v.creditNote.$touch()
      if (this.$v.creditNote.$invalid || this.$v.aggregations.$invalid) {
        return false
      }
      this.$dialog.warning({
        title: 'Crear nota de crédito',
        text: `
          <span class="d-block mb-4">Al continuar se</span>
          <span class="d-block pl-6">1. Ajustará el total de la factura a ${this.$options.filters.currency(this.totalAdjustDocumentsce, this.documents.currency)} ${this.documents.currency}</span>
          ${this.documents.document.has_local_format ? '<span class="d-block pl-6">2. Enviará la nota de crédito a la agencia fiscal de ' + this.account.country.name + '</span>' : ''}
          <span class="d-block my-4">¿Estás seguro de continuar?</span>
          <span class="d-block font-weight-medium">Esta acción no se puede deshacer</span>
        `,
        actions: [
          {
            color: 'primary',
            text: 'Crear nota de crédito',
            handle: () => {
              this.createLoader = true
              this.$store.dispatch('documents/CREATE', {
                resource: 'creditnotes',
                payload: this.creditNote,
                commitInstance: false
              })
              .then((response) => {
                this.$store.dispatch('documents/RETRIEVE', {
                  resource: 'creditnotes',
                  detail: 'finalize',
                  id: response.data.id,
                  loader: false,
                  setDetail: false
                })
                .then((response) => {
                  this.dialog = false
                  this.$router.push({
                    name: 'CreditNoteRetrieve',
                    params: {
                      ...this.$route.params,
                      id: response.data.id
                    }
                  })
                })
                .catch((error) => {
                  this.$store.dispatch('documents/DESTROY', {
                    resource: 'creditnotes',
                    id: response.data.id,
                    commitInstance: false
                  })
                  const errorData = error.response.data
                  if (errorData.non_field_errors) {
                    const handledError = this.handledErrors.find(he => errorData.non_field_errors.includes(he.snippet))
                    if (handledError) {
                      this.$dialog.message.error(handledError.message, {
                        timeout: 10000,
                        actions: [{
                          text: handledError.action,
                          color: 'red darken-4',
                          handle: () => {
                            if (handledError.route) {
                              const routerData = this.$router.resolve(handledError.route)
                              window.open(routerData.href, '_blank')
                            }
                          }
                        }]
                      })
                    } else {
                      this.$dialog.message.error(this.parseErrors(error.response.data))
                    }
                  } else {
                    this.$dialog.message.error(this.parseErrors(error.response.data))
                  }
                })
                .finally(() => {
                  this.createLoader = false
                })
              })
              .catch((error) => {
                this.$dialog.message.error(this.parseErrors(error.response.data))
                this.createLoader = false
              })
            }
          }
        ]
      })
    },
    addLine () {
      this.creditNote.lines.push({
        amount: null,
        description: '',
        discount: 0,
        discount_scheme: '%',
        exempt: !this.documents.document.has_taxes,
        name: '',
        quantity: 1
      })
    },
    setCreditNote () {
      this.creditNote = Object.assign({}, {
        date: this.today,
        discount: this.documents.discount,
        discount_scheme: this.documents.discount_scheme,
        documents: this.documents.id,
        internal_note: '',
        lines: this.documents.lines.map((l, i) => {
          const line = {
            amount: l.amount,
            description: l.description,
            discount: l.discount || 0,
            discount_scheme: l.discount_scheme || '%',
            exempt: this.documents.document.has_taxes ? l.exempt : true,
            name: l.name,
            quantity: l.quantity
          }
          if (this.documents.document.has_taxes && this.documents.document.has_lines_total) {
            line.amount = this.formatNumber(line.amount / ((this.documents.document.tax / 100) + 1), false)
          }
          return line
        }),
        note: ''
      })
      this.setAggregations()
      this.$v.creditNote.lines.$touch()
    },
    setAggregations () {
      const round = (this.activeCurrency.format && this.activeCurrency.format.precision === 0)
      let exempt = 0
      let amount = 0
      this.creditNote.lines.forEach((line, i) => {
        this.aggregations.lines[i] = {
          total: this.formatNumber((line.amount || 0) * (line.quantity || 0), round)
        }
        if (line.discount) {
          if (line.discount_scheme === '%') {
            this.aggregations.lines[i].total -= this.aggregations.lines[i].total * line.discount / 100
          } else {
            this.aggregations.lines[i].discount = this.formatNumber(line.discount, round)
            this.aggregations.lines[i].total -= this.aggregations.lines[i].discount
          }
        }
        if (line.exempt) {
          exempt += this.formatNumber(this.aggregations.lines[i].total, round)
        } else {
          amount += this.formatNumber(this.aggregations.lines[i].total, round)
        }
      })
      this.aggregations.exempt = this.formatNumber(exempt, round)
      this.aggregations.amount = this.formatNumber(amount, round)
      this.aggregations.subtotal = exempt + amount
      // discount
      if (this.creditNote.discount) {
        if (this.creditNote.discount_scheme === '%') {
          const percent = this.creditNote.discount / 100
          this.aggregations.amount -= this.formatNumber(this.aggregations.amount * percent, round)
          this.aggregations.exempt -= this.formatNumber(this.aggregations.exempt * percent, round)
        } else if (this.creditNote.discount_scheme === '$') {
          const amountPercent = this.aggregations.amount * 100 / this.aggregations.subtotal
          const exemptPercent = this.aggregations.exempt * 100 / this.aggregations.subtotal
          this.aggregations.amount -= this.formatNumber(this.creditNote.discount * amountPercent / 100, round)
          this.aggregations.exempt -= this.formatNumber(this.creditNote.discount * exemptPercent / 100, round)
        }
      }
      // taxes
      this.aggregations.taxes = this.formatNumber(this.documents.document.has_taxes_detail ? (this.aggregations.amount * (this.documents.document.tax / 100)) : 0, round)
      // total
      this.aggregations.total = this.formatNumber(this.aggregations.exempt + this.aggregations.amount + this.aggregations.taxes, round)
    }
  },
  validations: {
    aggregations: {
      total: {
        maxValue: function () {
          return this.totalAdjustedDocuments >= 0
        }
      }
    },
    creditNote: {
      date: {
        required
      },
      discount: {
        numeric,
        maxValue: function () {
          if (this.creditNote.discount_scheme === '$') {
            return this.creditNote.discount <= this.aggregations.subtotal
          }
          if (this.creditNote.discount_scheme === '%') {
            return this.creditNote.discount <= 100
          }
          return true
        }
      },
      internal_note: {
        maxLength: maxLength(1024)
      },
      lines: {
        $each: {
          amount: {
            required,
            decimal
          },
          description: {
            maxLength: maxLength(512)
          },
          discount: {
            numeric,
            maxValue: function (value, line) {
              if (line.discount_scheme === '$') {
                return value <= line.quantity * line.amount
              }
              if (line.discount_scheme === '%') {
                return value <= 100
              }
              return true
            }
          },
          name: {
            required,
            maxLength: maxLength(128)
          },
          quantity: {
            required,
            decimal
          }
        }
      },
      note: {
        required,
        maxLength: maxLength(64)
      }
    }
  }
}
</script>