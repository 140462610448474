import GenericViewMixin from '@/mixins/GenericViewMixin'
export default {
  props: {
    showCreate: {
      type: Boolean
    }
  },
  mixins: [
    GenericViewMixin
  ]
}
