export default [
  /* AXT */
  {
    id: "CRE",
    color: 'yellowLight',
    summary: 'Recibido en nuestra plataforma',
    icon: 'mdi-laptop'
  },
  {
    id: "MRT",
    color: 'yellowLight',
    summary: 'Documento reprocesado manualmente',
    icon: 'mdi-gesture-double-tap'
  },
  {
    id: "ART",
    color: 'yellowLight',
    summary: 'Procesando',
    icon: 'mdi-sync'
  },
  {
    id: "APR",
    color: 'lightBlue',
    summary: 'Documento aprobado por la agencia',
    icon: 'mdi-file-check'
  },
  {
    id: "OBS",
    color: 'purpleLight',
    summary: 'Documento aprobado con observaciones por la agencia',
    icon: 'mdi-file-eye'
  },
  {
    id: "SNT",
    color: 'blueGreyLighten',
    summary: 'Enviado a la agencia',
    icon: 'mdi-bank'
  },
  {
    id: "FAI",
    color: 'blueGreyLighten',
    summary: 'Pendiente',
    icon: 'mdi-clock'
  },
  {
    id: "REJ",
    color: 'redLight',
    summary: 'Rechazado por la agencia',
    icon: 'mdi-file-remove'
  },
  {
    id: "DUP",
    color: 'redLight',
    summary: 'Documento rechazado por duplicidad en la agencia',
    icon: 'mdi-file-multiple'
  },
  {
    id: "VOI",
    color: 'greyMedium',
    summary: 'Anulado',
    icon: 'mdi-file-remove'
  },
  {
    id: "EST",
    color: 'blueGreyLighten',
    summary: 'Enviado por mail',
    icon: 'mdi-email-arrow-right'
  }
  /* END AXT */
]